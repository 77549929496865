import React, { useState  } from 'react';
import './Dropdown.scss';
import { scales } from './constants'
import Dropdown from './Dropdown'

const SelectDropdown = ({ onChangeValue }) => {
    const [scale, setScale] = useState(null);

    const onChange = (event) => {
        onChangeValue(event);
        setScale(event);
    };
    return (
        <Dropdown
            label='-'
            options={scales}
            onChange={onChange}
            value={scale}
        />
    );
};

export default SelectDropdown
