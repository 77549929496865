import React, { useState } from 'react'
import './Dropdown.scss'
import Accordion from '../Accordion/Accordion'


const Dropdown = ({
                      data, onChangeSingle, onChangeMultiple, isLoading
                  }) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <div className='dropdown' style={{ top: 30, marginBottom: 60}}>
            <div
                onClick={(e) => {
                    if (data.length > 0) {
                        setIsActive(!isActive)
                    }
                }}
                className='dropdown-btn'
            />

            <div
                className='dropdown-content'
                style={{ display: isActive && !isLoading ? 'block' : 'none' }}
            >
                <Accordion data={data} onChangeSingle={onChangeSingle} onChangeMultiple={onChangeMultiple} />
            </div>
        </div>
    )
}

export default Dropdown
