import classes from './FindButton.module.scss'
import React from 'react'
import { useWindowSize } from '../../../hooks/useWindowSize'

export const FindButton = ({ onClick }) => {
    const {width} = useWindowSize()

    return (
        <button
            onClick={onClick}
            className={classes.FindButton}
        >
            {width && width > 767 && (
                <>Создать поисковой запрос</>
            )}
            {width && width <= 767 && (
                <>Создать поисковой запрос</>
            )}
        </button>
    )
}
