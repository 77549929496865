import React, { useState, useRef, useEffect } from 'react';
import './Dropdown.scss';
import IconChevronDown from '../Analise/icons/IconChevronDown'

const Dropdown = ({ label, options, onChange, value, showArrowDown = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
            <div className="dropdown-header" onClick={() => options.length > 0 ? setIsOpen(!isOpen) : null}>
                {value ? value?.name : label}
                {(showArrowDown || isOpen) && <IconChevronDown className={'icon-down'} />}
            </div>
            {isOpen && options.length > 0 && (
                <div className={`dropdown-list ${isOpen ? 'open' : ''}`}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`dropdown-item ${option?.id === value?.id ? 'selected' : ''}`}
                            onClick={() => {
                                onChange(option);
                                setIsOpen(false);
                            }}
                        >
                            {option.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown
