import React from 'react';
import './Dropdown.scss';
import Dropdown from './Dropdown'

const RatingDropdown = ({ data, onChangeValue, select }) => {
    const onChange = (event) => {
        onChangeValue(event);
    };

    return (
        <Dropdown
            label='-'
            options={data}
            onChange={(event) => onChange(event)}
            value={select}
            showArrowDown={true}
        />
    );
};

export default RatingDropdown
