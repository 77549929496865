export const findSystemOptions = [
  { id: 1, name: 'Яндекс XML', count: 1  },
  { id: 2, name: 'Яндекс Desktop', count: 1 },
  { id: 3, name: 'Яндекс Mobile', count: 1 },
  { id: 11, name: 'Google Desktop', count: 1},
  { id: 12, name: 'Google Mobile', count: 1 },
];

export const deepOptions = [
  { id: 5, name: '5', count: 1  },
  { id: 10, name: '10', count: 1 },
  { id: 30, name: '30', count: 1 },
  // { id: 50, name: '50', count: 1},
  // { id: 100, name: '100', count: 1 },
];

export const yandexRegions = [
  {
    name: 'C',
    year: 1972
  },
  {
    name: 'Elm',
    year: 2012
  },
];
