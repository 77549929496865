import React from 'react'
import classes from './Accordion.scss'
import UserRow from '../UserRow/UserRow'
import Collapsible from 'react-collapsible'
import cn from 'classnames'
import IconChevronDown from '../../../containers/Analise/icons/IconChevronDown'
import Switch from 'react-switch'


const Accordion = ({ data, onChangeSingle, onChangeMultiple }) => {

    const successAnalytics = data.some((item) => item.userId.role.accessLevel === 3)
    const successManagerBuilding = data.some((item) => item.userId.role.accessLevel === 4)
    const successManagerProject = data.some((item) => item.userId.role.accessLevel === 5)
    const successManagerBrand = data.some((item) => item.userId.role.accessLevel === 2)
    const ROLES = [
        {
            id: 2,
            title: 'Бренд-менеджер',
            show: successManagerBrand

        },
        {
            id: 3,
            title: 'Аналитик',
            show: successAnalytics
        },
        {
            id: 4,
            title: 'Менеджер производства',
            show: successManagerBuilding

        },
        {
            id: 5,
            title: 'Менеджер проекта',
            show: successManagerProject

        }
    ]

    return ROLES.map((current, index) => {
            const exist = data.some((user) => user.userId.role.accessLevel === current.id && user.allow)

            return current.show ? (
                    <Collapsible
                        key={index}
                        triggerTagName='div'
                        transitionTime={500}
                        transitionCloseTime={500}
                        easing='ease'
                        accordionPosition={index + 1}
                        className={cn(classes.Item)}
                        openedClassName={cn(classes.Item, classes.Active)}
                        trigger={
                            <>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ...(ROLES.length - 1 !== index && { borderBottom: '1px solid #D2DCEA' }),
                                    justifyContent: 'space-between',
                                    cursor: 'pointer'

                                }}>
                                    <div style={{
                                        display: 'flex', alignItems: 'center'
                                    }}
                                    >

                                        <p style={{
                                            fontWeight: '700',
                                            fontSize: 15,
                                            color: '#000000',
                                            marginRight: 8
                                        }}>{current.title}</p>
                                        <IconChevronDown />
                                    </div>

                                    <Switch offHandleColor={'#E9EFF5'}
                                            onHandleColor={'#119E00'}
                                            activeBoxShadow={'none'}
                                            offColor={'#aaa'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            height={21}
                                            width={48}
                                            onChange={(value, e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                onChangeMultiple(value, current.id)
                                            }
                                            }
                                            checked={exist} />
                                </div>
                            </>
                        }>
                        {
                            data
                                .map((user) => user.userId.role.accessLevel === current.id ?
                                    <UserRow name={user.userId.name} lastName={user.userId.lastName} active={user.allow}
                                             onChange={(value) => onChangeSingle(value, user.userId._id)} /> : null)
                        }
                    </Collapsible>
                ) :
                null
        }
    )
}

export default Accordion
