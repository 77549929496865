import axios from "axios";

const URL_SET = 'https://arsenkin.ru/tools/api/task/set'
const URL_CHECK = 'https://arsenkin.ru/tools/api/task/check'
export const URL_RESULT = 'https://arsenkin.ru/tools/api/task/result'
export const AUTOSAGGEST_URL = 'https://api.test-orm.ru/analysis/search'
export const TOKEN = 'f3ef1c67002817c354cba2638591c14d'

const systems = {
  1: 'yandex',
  2: 'yandex',
  3: 'yandex',
  11: 'google',
  12: 'google',
}

export const apiCreateTaskId = async (toolName, brandName, findSystem, regionId, deep) => {

  console.log(`apiCreateTaskId regionId: ${regionId}, brandName: ${brandName}, findSystem: ${findSystem}, deep: ${deep},`)
  const region = `&lr=${regionId}`;
    const response = await axios.get(`${URL_SET}?token=${TOKEN}
      &tools_name=${toolName}\
      &ss=${findSystem}
      ${region}
      &queries=[${JSON.stringify(brandName)}]
      &deep=${deep}
      &is_snippet=1
      &noreask=1`)

    if (response?.status === 200) {
        return response.data.task_id
    }

    return null
}
export const apiCheckTaskId = async (taskId) => {
    const result = await axios.get(`${URL_CHECK}?token=${TOKEN}&task_id=${taskId}`)

    if (result?.status === 200) {
        return result.data
    }

    return null
}



export const apiGetAutoSaggest = async (searchRequest, searchSystem) => {
  const response = await axios.get(`${AUTOSAGGEST_URL}?name=${searchRequest}&system=${systems[searchSystem]}`)
  if (response.status === 200) {
    return response.data.data
  }

}

const mocResponse = {
  request: {
    queries: [
      "чемодан",
      "купить чемодан"
    ],
    depth: 10,
    ss: [
      {
        ss: "Яндекс XML",
        region: "Москва [213]"
      },
      {
        ss: "Яндекс Desktop",
        region: "Москва [213]"
      },
      {
        ss: "Google Mobile",
        region: "Москва"
      }
    ],
    is_snippet: true
  },
  result: {
    collect: [
      [
        [
          "https://market.yandex.ru/catalog--chemodany/24715130/list",
          "https://www.ozon.ru/category/chemodany-1657/",
          "https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany",
          "https://sbermegamarket.ru/catalog/chemodany/",
          "https://www.chemodanpro.ru/",
          "https://www.kupi-chemodan.ru/",
          "https://robinzon.ru/catalog/suitcases/",
          "https://www.panchemodan.ru/catalog/chemodany/",
          "https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/",
          "https://www.rendez-vous.ru/catalog/bags/chemodan/"
        ],
        [
          "https://market.yandex.ru/catalog--chemodany/24715130/list",
          "https://www.ozon.ru/category/chemodany-1657/",
          "https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany",
          "https://www.rendez-vous.ru/catalog/bags/chemodan/",
          "https://sbermegamarket.ru/catalog/chemodany/",
          "https://www.panchemodan.ru/catalog/chemodany/",
          "https://robinzon.ru/catalog/suitcases/",
          "https://www.chemodanpro.ru/",
          "https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/",
          "https://www.lamoda.ru/c/7604/accs-women-suitcases/"
        ],
        [
          "https://www.ozon.ru/category/chemodany-1657/",
          "https://robinzon.ru/catalog/suitcases/",
          "https://m.market.yandex.ru/catalog--chemodany/24715130/list",
          "https://sbermegamarket.ru/catalog/chemodany/",
          "https://www.chemodan.ru/",
          "https://ru.m.wikipedia.org/wiki/%D0%A7%D0%B5%D0%BC%D0%BE%D0%B4%D0%B0%D0%BD",
          "https://www.afisha.ru/msk/restaurant/chemodan-33086/",
          "https://www.montblanc.com/ru-ru/kozha/kategorii/bagazh-i-aksessuary-dlya-puteshestviy/chemodan-dlya-ruchnoy-kladi-my4810-2.html",
          "https://www.tripadvisor.ru/Restaurant_Review-g298484-d3184240-Reviews-Chemodan-Moscow_Central_Russia.html",
          "https://www.panchemodan.ru/catalog/chemodany/"
        ]
      ],
      [
        [
          "https://www.ozon.ru/category/chemodany-1657/",
          "https://market.yandex.ru/catalog--chemodany/24715130/list",
          "https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany",
          "https://www.kupi-chemodan.ru/",
          "https://robinzon.ru/catalog/suitcases/",
          "https://sbermegamarket.ru/catalog/chemodany/",
          "https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/",
          "https://www.rendez-vous.ru/catalog/bags/chemodan/",
          "https://www.panchemodan.ru/catalog/chemodany/",
          "https://www.lamoda.ru/c/7604/accs-women-suitcases/"
        ],
        [
          "https://market.yandex.ru/catalog--chemodany/24715130/list",
          "https://www.ozon.ru/category/chemodany-1657/",
          "https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany",
          "https://robinzon.ru/catalog/suitcases/",
          "https://sbermegamarket.ru/catalog/chemodany/",
          "https://www.rendez-vous.ru/catalog/bags/chemodan/",
          "https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/",
          "https://www.lamoda.ru/c/7604/accs-women-suitcases/",
          "https://www.panchemodan.ru/catalog/chemodany/",
          "https://www.kupi-chemodan.ru/"
        ],
        [
          "https://www.ozon.ru/category/chemodany-1657/",
          "https://robinzon.ru/catalog/suitcases/",
          "https://m.market.yandex.ru/catalog--chemodany/24715130/list",
          "https://sbermegamarket.ru/catalog/chemodany/",
          "https://columbos.ru/catalog/suitcases/",
          "https://chemodan-kupit.ru/catalog/chemodany-na-kolesakh/",
          "https://www.chemodan.ru/",
          "https://www.panchemodan.ru/catalog/chemodany/",
          "https://sweetbags.ru/categories/chemodany",
          "https://redmond.ru/catalog/cemodany"
        ]
      ]
    ],
    snippets: {
      'https://market.yandex.ru/catalog--chemodany/24715130/list': [
        {
          title: "Чемоданы — купить по низкой цене на Яндекс Маркете",
          snippet: "Чемоданы — купить по выгодной цене с доставкой. 6382 модели в проверенных интернет-магазинах: популярные новинки и лидеры продаж. Поиск по параметрам, удобное сравнение моделей и цен на Яндекс Маркете."
        },
        {
          title: "Чемоданы — купить по низкой цене на Яндекс Маркете",
          snippet: "Чемоданы — купить по выгодной цене с доставкой. 6382 модели в проверенных интернет-магазинах: популярные новинки и лидеры продаж. Поиск по параметрам, удобное сравнение моделей и цен на Яндекс Маркете."
        }
      ],
      'https://www.ozon.ru/category/chemodany-1657/': [
        {
          title: "Чемоданы купить в интернет-магазине OZON",
          snippet: ""
        },
        {
          title: "Чемоданы купить в интернет-магазине OZON",
          snippet: "Чемоданы - купить в интернет-магазине OZON по выгодным ценам! Характеристики Фото Огромный ассортимент Настоящие отзывы покупателей! Быстрая доставка по всей России!"
        },
        {
          title: "Чемоданы купить в интернет-магазине OZON",
          snippet: "Чемоданы - купить в интернет-магазине OZON по выгодным ценам! Характеристики ✓ Фото ✓ Огромный ассортимент ✓ Настоящие отзывы покупателей!"
        }
      ],
      'https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany': [
        {
          title: "Большой выбор чемоданов в интернет-магазине WildBerries.ru. Бесплатная доставка и постоянные скидки!",
          snippet: "К сожалению, именно такого товара сейчас нет. Попробуйте поискать с другими параметрами или посмотрите все товары в категории Чемоданы. Товаров пока нет. Загляните на главную – там найдется что-то интересное."
        },
        {
          title: "Купить чемоданы в интернет магазине WildBerries.ru",
          snippet: "Большой выбор чемоданов в интернет-магазине WildBerries.ru. Бесплатная доставка и постоянные скидки! ... Попробуйте поискать с другими параметрами или посмотрите все товары в категории Чемоданы. Товаров пока нет. Загляните на главную – там найдется что-то интересное."
        }
      ],
      'https://sbermegamarket.ru/catalog/chemodany/': [
        {
          title: "Чемоданы - купить чемодан, цены на sbermegamarket.ru",
          snippet: "Маркетплейс sbermegamarket.ru – место выгодной покупки чемоданов от 926 рублей с кэшбэком до 14127 бонусных рублей в интернет-магазинах. В ассортименте более 9893 товаров! Телефон для заказа: 8 (800) 600-08-88."
        },
        {
          title: "Чемоданы - купить чемодан, цены на sbermegamarket.ru",
          snippet: "Маркетплейс sbermegamarket.ru – место выгодной покупки чемоданов от 926 рублей с кэшбэком до 14127 бонусных рублей в интернет-магазинах. В ассортименте более 9893 товаров! Телефон для заказа: 8 (800) 600-08-88. ... Поможем выбрать и купить чемодан в интернет-магазинах с удобной доставкой до дома или офиса. В ассортименте более 9893 позиций. Кэшбэк до 14127 бонусных рублей. Узнайте, сколько стоят чемоданы, цены начинаются от 926 рублей. Подборки. размер M на колесах S для ручной клади для ручной клади легкие для командировок большие из полипропилена размер L из поликарбоната Samsonite 4 х колесные. Маркетплейс. "
        },
        {
          title: "купить чемодан, цены на sbermegamarket.ru",
          snippet: "Маркетплейс sbermegamarket.ru – место выгодной покупки чемоданов от 1558 рублей с кэшбэком до 21619 бонусных ру ..."
        }
      ],
      'https://www.chemodanpro.ru/': [
        {
          title: "ЧемоданPRO- интернет-магазин с доставкой по Москве и России, продажа чемоданов и сумок по выгодным ценам",
          snippet: "Надежные дорожные чемоданы на колесах в интернет-магазине ЧемоданPRO. Большой выбор пластиковых чемоданов в каталоге с доставкой по России."
        },
        {
          title: "ЧемоданPRO- интернет-магазин с доставкой по Москве...",
          snippet: "Надежные дорожные чемоданы на колесах в интернет-магазине ЧемоданPRO. Большой выбор пластиковых чемоданов в каталоге с доставкой по России."
        }
      ],
      'https://www.kupi-chemodan.ru/': [
        {
          title: "Купить недорогой чемодан на колесах с бесплатной доставкой по Москве",
          snippet: "Недорогие и качественные чемоданы на колесах с бесплатной доставкой по Москве и всей РФ. Официальный сайт интернет-магазина Kupi-chemodan.ru."
        },
        {
          title: "Купить недорогой чемодан на колесах с бесплатной...",
          snippet: "Недорогие и качественные чемоданы на колесах с бесплатной доставкой по Москве и всей РФ. Официальный сайт интернет-магазина Kupi-chemodan.ru. ... Большой выбор дизайна и цвета, надежная фурнитура и устойчивость к царапинам сделали чемоданы Verano весьма популярными среди путешественников, а низкая цена сделала их доступными к покупке широкому кругу покупателей. На все чемоданы распространяется гарантия производителя от одного года до пяти лет. По Москве в пределах МКАД производится бесплатная экспресс-доставка в день заказа. "
        }
      ],
      'https://robinzon.ru/catalog/suitcases/': [
        {
          title: "Чемоданы на колесах - купить в интернет-магазине Робинзон",
          snippet: ""
        },
        {
          title: "Чемоданы на колесах - купить в интернет-магазине...",
          snippet: "В нашем интернет-магазине всегда есть в наличии алюминиевые чемоданы Rimowa, которые отличаются своим немецким качеством и богатой внутренней отделкой. Купить чемодан в интернет-магазине Robinzon можно быстро и просто! Какой чемодан купить, прочный пластиковый, удобный тканевый, или, может быть, сделанный по последним разработкам гибридный, выбирать Вам. В Интернет-магазине чемоданов Робинзон.ру Вы найдете только качественные и надежные чемоданы, которые прослужат долгие годы. В каталоге интернет-магазина Робинзон.ру также представлены сумки, рюкзаки, модели для деловых поездок, товары для детей и аксессуары для путешествий. "
        },
        {
          title: "Чемоданы на колесах - купить в интернет-магазине Робинзон",
          snippet: "Заказать чемодан высокого качества от популярных брендов с бесплатной доставкой ✔️при заказе от 2500 рублей. ✔️ Новые коллекции, ✔️ широкий ..."
        }
      ],
      'https://www.panchemodan.ru/catalog/chemodany/': [
        {
          title: "Дорожные чемоданы на колесах: купить в Москве в интернет-магазине panchemodan.ru",
          snippet: ""
        },
        {
          title: "Дорожные чемоданы на колесах: купить в Москве...",
          snippet: "Колеса. Лучше купить чемодан на четырех колесиках — такие модели самые простые в эксплуатации. Колесики лучше выбирать резиновые. Они устойчивее пластиковых и не шумят. ... Дело не только в трендах. Яркий чемодан сложнее потерять в путешествии — вы не спутаете его с другими и сразу заметите на багажной ленте. Для таких целей также можно купить яркий чехол. Чемоданы мировых брендов. Бренд изделия — еще один важный критерий удачной покупки. «Пан Чемодан» — интернет-магазин чемоданов, где представлены одни из лучших торговых марок в разных ценовых категориях. В каталоге вы сможете найти чемоданы от American Tourister, DKNY, Eberhart, Calvin Klein, Samsonite и других мировых брендов. Наш магазин чемоданов — это простой и приятный онлайн-шопинг. Чтобы сделать процесс покупок комфортнее, используйте фильтры — по брендам, материалу, цвету, размеру, цене. Так вы сможете исключить из поиска товары, которые вам не подходят, и быстро найти нужную модель. Если остались вопросы, вы всегда можете обратиться к нашим менеджерам. "
        },
        {
          title: "Дорожные чемоданы на колесах: купить в Москве в интернет ...",
          snippet: "Дорожные чемоданы на колесах для путешествий. Широкий ассортимент, гарантия качества, ежедневные скидки! Бесплатная доставка в Москве и Санкт-Петербурге, ..."
        }
      ],
      'https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/': [
        {
          title: "Чемоданы — купить в интернет-магазине “Империя Сумок”, выбрать и заказать товар хорошего качества можно в каталоге с ценами и фото",
          snippet: ""
        },
        {
          title: "Чемоданы — купить в интернет-магазине “Империя...”",
          snippet: "Выбрать и купить чемодан хорошего качества в интернет-магазине “Империя Сумок” поможет подбор по параметрам и размерам, у нас только живые фотографии и реальные отзывы. Ассортимент магазина включает надежные и удобные изделия стоимостью от 3590 рублей. Забрать покупку можно самовывозом или заказав доставку по адресу. Работает дисконтная программа, скидки в день рождения, регулярные акции и распродажи. На все товары предоставляется гарантия. "
        }
      ],
      'https://www.rendez-vous.ru/catalog/bags/chemodan/': [
        {
          title: "Чемоданы - купить в Москве",
          snippet: "Чемоданы - купить в Москве. Новые коллекции и скидки до 80% в интернет-магазине Rendez-Vous. Сертификаты качества. Бесплатная доставка по всей Россий. Более 100 магазинов по России."
        },
        {
          title: "Чемоданы - купить в Москве",
          snippet: "Чемоданы - купить в Москве. Новые коллекции и скидки до 80% в интернет-магазине Rendez-Vous. Сертификаты качества. Бесплатная доставка по всей Россий. Более 100 магазинов по России."
        }
      ],
      'https://www.lamoda.ru/c/7604/accs-women-suitcases/': [
        {
          title: "Женские чемоданы — купить в интернет-магазине Ламода",
          snippet: "Женские чемоданы с бесплатной доставкой в интернет-магазине Ламода, актуальные цены, в наличии большой ассортимент моделей."
        },
        {
          title: "Женские чемоданы — купить в интернет-магазине Ламода",
          snippet: "Женские чемоданы с бесплатной доставкой в интернет-магазине Ламода, актуальные цены, в наличии большой ассортимент моделей. ... Вы можете оплатить покупки наличными при получении, либо выбрать другой способ оплаты. Для мобильных устройств. Вы также можете перейти на основной сайт. "
        }
      ],
      'https://m.market.yandex.ru/catalog--chemodany/24715130/list': {
        2: {
          title: "Чемоданы — купить по низкой цене на Яндекс Маркете",
          snippet: "Чемоданы — купить по выгодной цене с доставкой. 6495 моделей в проверенных интернет-магазинах: популярные нови ..."
        }
      },
      'https://www.chemodan.ru/': {
        2: {
          title: "Купить чемодан на колесах в интернет магазине Чемодан.РУ",
          snippet: "Купить чемодан в интернет магазине Чемодан.ру. Быстрая доставка. Низкие цены. Тел 8-495-771-11-83 или 8-800-234- ..."
        }
      },
      'https://ru.m.wikipedia.org/wiki/%D0%A7%D0%B5%D0%BC%D0%BE%D0%B4%D0%B0%D0%BD': {
        2: {
          title: "Чемодан - Википедия",
          snippet: "Чемода́н (от перс. جامه دان\u200e джомадан: جامه jāma одежда + دان dān хранитель) — тип сумки, представляющий собой коробку"
        }
      },
      'https://www.afisha.ru/msk/restaurant/chemodan-33086/': {
        2: {
          title: "Ресторан Чемодан у метро Кропоткинская в Москве - Afisha.ru",
          snippet: "Ресторан Чемодан у метро Кропоткинская - Артистический ресторан с сибирской кухней. В меню пельмени, русская кухня. Средний чек - больше 3000 рублей."
        }
      },
      'https://www.montblanc.com/ru-ru/kozha/kategorii/bagazh-i-aksessuary-dlya-puteshestviy/chemodan-dlya-ruchnoy-kladi-my4810-2.html': {
        2: {
          title: "Чемодан для ручной клади #MY4810 - Montblanc",
          snippet: "Чемодан для ручной клади #MY4810: смотрите все детали. Выберите стандартную или экспресс-доставку и сделайте покупку в официальном онлайн-бутике Montblanc ..."
        }
      },
      'https://www.tripadvisor.ru/Restaurant_Review-g298484-d3184240-Reviews-Chemodan-Moscow_Central_Russia.html': {
        2: {
          title: "Чемодан, Москва - фото ресторана - TripAdvisor",
          snippet: "Чемодан, Москва: просмотрите 416 объективных отзывов о Чемодан с оценкой 4,5 из 5 на сайте Tripadvisor и рейтингом ..."
        }
      },
      'https://columbos.ru/catalog/suitcases/': {
        2: {
          title: "Купить чемодан хорошего качества на колесах в интернет-магазине ...",
          snippet: "Чемодан для путешествия: выбираем удобный и стильный аксессуар. Купите качественный чемодан в интернет-магазине ..."
        }
      },
      'https://chemodan-kupit.ru/catalog/chemodany-na-kolesakh/': {
        2: {
          title: "Купить недорогие чемоданы на колесах хорошего качества, цены ...",
          snippet: "Продажа чемоданов на колесах в интернет-магазине в Москве. В каталоге дорожные чемоданы на колесах с ценами, фо ..."
        }
      },
      'https://sweetbags.ru/categories/chemodany': {
        2: {
          title: "Чемоданы - купить по выгодным ценам в Санкт-Петербурге",
          snippet: "Купить чемодан в СПб позволяет обширный каталог магазина sweetbags.ru с оперативной доставкой, приятными цена ..."
        }
      },
      'https://redmond.ru/catalog/cemodany': {
        2: {
          title: "Чемоданы купить в Москве - Redmond.Ru",
          snippet: "Чемоданы в интернет-магазине Рэдмонд: сеть салонов кожгалантереи, сумок, чемоданов и рюкзаков | Оперативная дос ..."
        }
      }
    },
    main: [
      [
        {
          4: true,
          5: true
        },
        {
          7: true
        },
        {
          4: true
        }
      ],
      [
        {
          3: true
        },
        {
          9: true
        },
        {
          6: true
        }
      ]
    ],
    ads: {
      0: {
        1: {
          bottom: 4
        },
        2: []
      },
      1: {
        1: {
          bottom: 4
        },
        2: []
      }
    },
    agregators: [
      {
        2: {
          6: true,
          8: true
        }
      }
    ],
    repeatUrl: {
      'https://www.ozon.ru/category/chemodany-1657/': 6,
      'https://sbermegamarket.ru/catalog/chemodany/': 6,
      'https://robinzon.ru/catalog/suitcases/': 6,
      'https://www.panchemodan.ru/catalog/chemodany/': 6,
      'https://market.yandex.ru/catalog--chemodany/24715130/list': 4,
      'https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany': 4,
      'https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/': 4,
      'https://www.rendez-vous.ru/catalog/bags/chemodan/': 4,
      'https://www.kupi-chemodan.ru/': 3,
      'https://www.lamoda.ru/c/7604/accs-women-suitcases/': 3,
      'https://www.chemodanpro.ru/': 2,
      'https://m.market.yandex.ru/catalog--chemodany/24715130/list': 2,
      'https://www.chemodan.ru/': 2
    },
    repeatHost: {
      'ozon.ru': 6,
      'sbermegamarket.ru': 6,
      'robinzon.ru': 6,
      'panchemodan.ru': 6,
      'market.yandex.ru': 4,
      'wildberries.ru': 4,
      'imperiasumok.ru': 4,
      'rendez-vous.ru': 4,
      'kupi-chemodan.ru': 3,
      'lamoda.ru': 3,
      'chemodanpro.ru': 2,
      'm.market.yandex.ru': 2,
      'chemodan.ru': 2
    },
    repeatUrlSS: {
      'https://market.yandex.ru/catalog--chemodany/24715130/list': [
        2,
        2
      ],
      'https://www.ozon.ru/category/chemodany-1657/': [
        2,
        2,
        2
      ],
      'https://www.wildberries.ru/catalog/aksessuary/sumki-i-ryukzaki/chemodany': [
        2,
        2
      ],
      'https://sbermegamarket.ru/catalog/chemodany/': [
        2,
        2,
        2
      ],
      'https://www.chemodanpro.ru/': [
        1,
        1
      ],
      'https://www.kupi-chemodan.ru/': [
        2,
        1
      ],
      'https://robinzon.ru/catalog/suitcases/': [
        2,
        2,
        2
      ],
      'https://www.panchemodan.ru/catalog/chemodany/': [
        2,
        2,
        2
      ],
      'https://www.imperiasumok.ru/catalog/sumki-dlya-bagazha/chemodany/': [
        2,
        2
      ],
      'https://www.rendez-vous.ru/catalog/bags/chemodan/': [
        2,
        2
      ],
      'https://www.lamoda.ru/c/7604/accs-women-suitcases/': {
        0: 1,
        1: 2
      },
      'https://m.market.yandex.ru/catalog--chemodany/24715130/list': {
        2: 2
      },
      'https://www.chemodan.ru/': {
        2: 2
      }
    },
    repeatHostSS: {
     ' market.yandex.ru': [
        2,
        2
      ],
      'ozon.ru': [
        2,
        2,
        2
      ],
      'wildberries.ru': [
        2,
        2
      ],
      'sbermegamarket.ru': [
        2,
        2,
        2
      ],
      'chemodanpro.ru': [
        1,
        1
      ],
      'kupi-chemodan.ru': [
        2,
        1
      ],
      'robinzon.ru': [
        2,
        2,
        2
      ],
      'panchemodan.ru': [
        2,
        2,
        2
      ],
      'imperiasumok.ru': [
        2,
        2
      ],
      'rendez-vous.ru': [
        2,
        2
      ],
      'lamoda.ru': {
        0: 1,
        1: 2
      },
      'm.market.yandex.ru': {
        2: 2
      },
      'chemodan.ru': {
        2: 2
      }
    },
    repeatHostSecond: {
      'yandex.ru': 6,
      'ozon.ru': 6,
      'sbermegamarket.ru': 6,
      'robinzon.ru': 6,
      'panchemodan.ru': 6,
      'wildberries.ru': 4,
      'imperiasumok.ru': 4,
      'rendez-vous.ru': 4,
      'kupi-chemodan.ru': 3,
      'lamoda.ru': 3,
      'chemodanpro.ru': 2,
      'chemodan.ru': 2
    },
    repeatHostSecondColors: {
      'yandex.ru': 0,
      'ozon.ru': 1,
      'sbermegamarket.ru': 2,
      'robinzon.ru': 3,
      'panchemodan.ru': 4,
      'wildberries.ru': 5,
      'imperiasumok.ru': 6,
      'rendez-vous.ru': 7,
      'kupi-chemodan.ru': 8,
      'lamoda.ru': 9,
      'chemodanpro.ru': 10,
      'chemodan.ru': 11
    }
  }
}
