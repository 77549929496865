
export const columns = [
  { key: 'host', name: 'Домен' },
  { key: 'url', name: 'URL' },
  { key: 'scale', name: 'Шкала' },
  { key: 'mark', name: 'Оценка' },
  { key: 'header', name: 'Заголовок' },
  { key: 'description', name: 'Описание' },
  { key: 'tonality', name: 'Тональность' },
];

export const headers = ['Домен', 'URL', 'Шкала', 'Рейтинг', 'Заголовок', 'Описание', 'Тональность'];

export const scales = [
    { name: "-", id: null, count: 1 },
    { name: "5 балльная", id: 'scale5', count: 1},
    { name: "10 балльная", id: 'scale10', count: 1},
];

export const scale5 = [
    {name: 1, id: 'scale5-1', count: 1},
    {name: 2, id: 'scale5-2', count: 1},
    {name: 3, id: 'scale5-3', count: 1},
    {name: 4, id: 'scale5-4', count: 1},
    {name: 5, id: 'scale5-5', count: 1}
];

export const scale10 = [
    {name: 1, id: 'scale10-1', count: 1},
    {name: 2, id: 'scale10-2', count: 1},
    {name: 3, id: 'scale10-3', count: 1},
    {name: 4, id: 'scale10-4', count: 1},
    {name: 5, id: 'scale10-5', count: 1},
    {name: 6, id: 'scale10-6', count: 1},
    {name: 7, id: 'scale10-7', count: 1},
    {name: 8, id: 'scale10-8', count: 1},
    {name: 9, id: 'scale10-9', count: 1},
    {name: 10, id: 'scale10-10', count: 1}
];

export const rating5 = [
    { name: "-", id: null, count: 1 },
    {name: 1, id: 'rating-1', count: 1},
    {name: 2, id: 'rating-2', count: 1},
    {name: 3, id: 'rating-3', count: 1},
    {name: 4, id: 'rating-4', count: 1},
    {name: 5, id: 'rating-5', count: 1}
];
export const rating10 = [
    { name: "-", id: null, count: 1 },
    {name: 1, id: 'rating-1', count: 1},
    {name: 2, id: 'rating-2', count: 1},
    {name: 3, id: 'rating-3', count: 1},
    {name: 4, id: 'rating-4', count: 1},
    {name: 5, id: 'rating-5', count: 1},
    {name: 6, id: 'rating-6', count: 1},
    {name: 7, id: 'rating-7', count: 1},
    {name: 8, id: 'rating-8', count: 1},
    {name: 9, id: 'rating-9', count: 1},
    {name: 10, id: 'rating-10', count: 1}
];
