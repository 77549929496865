import React, { useState } from 'react'
import './Dropdown.scss'
import { rating10, rating5 } from './constants'
import Dropdown from './Dropdown'
import classes from './Search.module.scss'
import SelectDropdown from './ScaleDropdown'
import RatingDropdown from './RatingDropdown'
import SelectWithCounter from '../../components/UI/SelectWithCounter/SelectWithCounter'
import { tonality } from './Search'

const Row = ({ data, result, rowIndex, onTapUrl, onChangeRating, onChangeTonality }) => {

    const [scale, setScale] = useState(null)
    const [rating, setRating] = useState(null)

    const handleScaleChange = (value) => {
        setScale(value)
        setRating(null)
    }

    const handleRatingChange = (value) => {
        setRating(value)
    }

    return <tr
        className={classes.TableRow}>{Object.entries(data).map(([key, value]) => {

        if (key === 'scale') {
            return (
                <td style={{ verticalAlign: 'middle', height: 150 }}>
                    <div style={{ display: 'flex', width: 150 }}>
                        <SelectDropdown onChangeValue={handleScaleChange} />

                    </div>

                </td>
            )
        }
        if (key === 'rating') {
            if (scale?.id === 'scale5') {
                return (
                    <td style={{ alignContent: 'center', width: 50 }}>

                        <RatingDropdown data={rating5} onChangeValue={(event) => {
                            handleRatingChange(event)
                            onChangeRating(event, rowIndex, scale)
                        }} select={rating} />

                    </td>
                )
            }

            if (scale?.id === 'scale10') {
                return (
                    <td style={{ alignContent: 'center', width: 50 }}>
                        <RatingDropdown data={rating10}
                                        onChangeValue={(event) => {
                                            handleRatingChange(event)
                                            onChangeRating(event, rowIndex, scale)
                                        }} select={rating} />
                    </td>
                )
            }
            return (
                <td style={{ alignContent: 'center', width: 50 }}>
                    <Dropdown
                        label='-'
                        options={[]}
                        showArrowDown={false}
                    />
                </td>
            )


        }
        if (key === 'tonality') {
            return (
                <td style={{ alignContent: 'center', padding: '0 10px 0 10px', maxWidth: '70px' }}>
                    <SelectWithCounter
                        optionDefault={tonality.find((item) => item.id === result[rowIndex].tonality).name}
                        isHideCount
                        options={tonality.filter(obj => obj.id !== result[rowIndex].tonality)}
                        onChange={(event) => onChangeTonality(event, rowIndex)}
                    />
                </td>
            )
        }

        if (key === 'url') {
            return (
                <td
                    className={classes.TableCell}
                    style={{
                        alignContent: 'center',
                        padding: 10,
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    key={`${value}-${Math.random()}`}
                >
                    <a href={value} style={{ color: 'orange' }} onClick={(event) => onTapUrl(event, value)}
                       target='_blank' rel='noopener noreferrer'>{value}</a>
                </td>
            )
        }
        return (
            <td
                className={classes.TableCell}
                style={{
                    alignContent: 'center',
                    padding: 10,
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
                key={`${value}-${Math.random()}`}
            >
                {value}
            </td>
        )
    })} </tr>
}

export default Row
