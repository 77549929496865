import React, { useCallback, useState, useMemo } from 'react'

import Modal from 'react-modal'
import cn from 'classnames'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { apiCreateTaskId } from '../../../api/search'

import { TextField } from '../../Search/TextField/TextField'
import SelectWithCounter from '../../UI/SelectWithCounter/SelectWithCounter'

import { deepOptions, findSystemOptions } from './constants'

import classes from './SearchModule.module.scss'
import Autosuggest from 'react-autosuggest'
import { defaultTheme } from 'react-autosuggest'
import { debounce } from '../../../utils/debounce'
import { apiGetAutoSaggest } from '../../../api/search'


Modal.setAppElement('#root')

const isLatin = (text) => /[a-zA-Z]/.test(text);


export const SearchModal = ({
                                deep,
                                brandName,
                                findSystem,
                                regionId,
                                region,
                                isVisible,
                                setTaskId,
                                onClose,
                                suggestions,
                                onFindSystem,
                                onSuggestions,
                                onRegionName,
                                onBrandName,
                                onRegionId,
                                onDeep
                            }) => {
    const { width } = useWindowSize()
    const [errorRegion, setErrorRegion] = useState(null)
    const [errorBrand, setErrorBrand] = useState(null)

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            height: width < 768 ? '100%' : '700px',
            width: width < 768 ? '100%' : '30%',
            transform: 'translate(-50%, -50%)'
        }
    }

    const handleCreateTaskId = async () => {

        if (brandName === '' || regionId == null) {
            if (regionId == null) {
                setErrorRegion('Выберите регион')
            }


            if (brandName === '') {
                setErrorBrand('Заполните поле')
            }

            return
        }

        try {

            const taskId = await apiCreateTaskId('top10', brandName, findSystem, regionId, deep)
            if (taskId) {
                setTaskId(taskId)
                onClose()
            } else {
                console.log('task_id not defined')
            }
        } catch (error) {

        }
    }

    const onChangeRegion = (event) => {
        onRegionName(event.target.value)
    }

    const inputProps = {
        placeholder: 'Введите регион поиска',
        value: region ? region : '',
        onChange: onChangeRegion,
        onFocus: (e) => setErrorRegion(null)
    }

    const onChangeDeep = useCallback((event) => {
        onDeep(event.target.value)
    }, [])

    const onSuggestionsFetchRequested = useCallback(async ({ value }) => {
        onSuggestions(await apiGetAutoSaggest(value, findSystem))
    }, [findSystem])

    const onSuggestionsClearRequested = () => {
        onSuggestions([])
    }

    const renderSuggestion = suggestion => (
        <div className={classes.SuggestRow}>
            {isLatin(region) ? suggestion.region : suggestion.name}
        </div>
    )

    const onChangeFindSystem = useCallback((event) => {
        onFindSystem(event.target.value)
        onRegionName('')
        onRegionId(null)
    }, [])

    const renderInputComponent = (inputProps) => <TextField
        label='Регион:'
        fullWidth={true}
        border='all'
        {...inputProps}
    />

    const getSuggestionValue = (suggestion) => {
        setTimeout(() => {
            onRegionName(suggestion.name)

            onRegionId(suggestion.id)
        }, 100)
    }
    const debounceRegions = useMemo(() => debounce(onSuggestionsFetchRequested, 500), [onSuggestionsFetchRequested])

    return (
        <Modal
            style={customStyles}
            isOpen={isVisible}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={false}
            contentLabel='Поисковой запрос'
        >
            <div className={cn(classes.Tile, classes.Active)}>
                <div className={classes.Header}>
                    <div className={classes.Text}>Поисковой запрос</div>
                </div>
                <div className={classes.Body}>
                    <div className={classes.FolderIcon}></div>
                    <TextField
                        label='Фраза для проверки или название бренда:'
                        fullWidth={true}
                        border={'all'}
                        value={brandName}
                        onChange={(e) => onBrandName(e.target.value)}
                        onFocus={(e) => setErrorBrand(null)}
                    />
                    {errorBrand && <p style={{ color: 'red' }}>{errorBrand}</p>}

                    <SelectWithCounter
                        className={cn(classes.Field)}
                        value={findSystem}
                        options={findSystemOptions}
                        isHideCount
                        maxHeight='200px'
                        onChange={onChangeFindSystem}
                    />
                    <div style={{ height: 10 }} />
                    <Autosuggest
                        theme={{
                            ...defaultTheme,
                            container: classes.react_autosuggest__suggestions_container,
                            input: classes.react_autosuggest__input,
                            inputOpen: classes.react_autosuggest__input__open,
                            inputFocused: classes.react_autosuggest__input__focused,
                            suggestionsContainer: classes.react_autosuggest__suggestions_container,
                            suggestionsContainerOpen: classes.react_autosuggest__suggestions_container__open,
                            suggestionsList: classes.react_autosuggest__suggestions_list,
                            suggestion: classes.react_autosuggest__suggestion,
                            suggestionHighlighted: classes.react_autosuggest__suggestion__highlighted
                        }
                        }
                        suggestions={suggestions}
                        renderInputComponent={renderInputComponent}
                        onSuggestionsFetchRequested={debounceRegions}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />

                    {errorRegion && <p style={{ color: 'red' }}>{errorRegion}</p>}

                    <div style={{ height: 10 }} />
                    <SelectWithCounter
                        className={cn(classes.Field)}
                        value={deep}
                        options={deepOptions}
                        isHideCount
                        maxHeight='200px'
                        onChange={onChangeDeep}
                    />
                </div>
                <div className={classes.Footer}>
                    <button onClick={handleCreateTaskId} className={classes.Create}>Создать</button>
                    <button onClick={onClose} className={classes.Cancel}>Отменить</button>
                </div>
            </div>
        </Modal>
    )
}
